<script>
import Nav from "@/components/Nav/index.vue";
import { nextTick } from "vue";
export default {
    name: "NcoinInstruction",
    data() {
        return {
            detailIndex: null,
        };
    },
    components: {
        Nav,
    },
    computed: {
        userNbInfo() {
            return this.$store.state.userNbInfo;
        },
    },
    watch: {
        userNbInfo: function () {
            let vm = this;
            if (!vm.userNbInfo.data.user.isBind) {
                vm.$router.push("/welcome");
            }
        },
    },
    mounted() {
        nextTick(() => {
            let vm = this;
            if (vm.userNbInfo.data) {
                if (!vm.userNbInfo.data.user.isBind) {
                    vm.$router.push("/welcome");
                }
            }
        });
    },
    methods: {
        showFullInfo(index) {
            // console.log("showFullInfo");
            let vm = this;
            if (index === vm.detailIndex) {
                vm.detailIndex = null;
            } else {
                vm.detailIndex = index;
            }
        },
    },
};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
